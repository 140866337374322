import React from "react";
import "./top.css";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import Load from "../Load";

const TopProd = () => {
  const { isPending, error, data } = useQuery({
    queryKey: ["tops"],
    queryFn: () =>
      makeRequest.get("/posts/tops").then((res) => {
        return res.data;
      }),
  });
  return (
    <div style={{ width: "100%" }}>
      {error ? (
        <p className="text-capitalize text-center">Something went wrong</p>
      ) : isPending ? (
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Load />
        </div>
      ) : data && data.length < 5 ? (
        ""
      ) : (
        <div className="cont">
          <div className="nav">
            <div>Top Producs</div> <a href="/exclusive">See All</a>
          </div>

          <div className="prodCont">
            {data.map((post) => {
              const formattedPrice = new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(post.price);
              return (
                <a
                  className="prod shadow-sm"
                  href={`/item/${post.id}/`}
                  key={post.id}
                >
                  <img className="img" src={`${post.mainimg}`} alt="" />
                  <p>
                    {post.promoted === "true" && (
                      <img
                        src={"../../img/Goldbadge.png"}
                        style={{
                          width: "14px",
                          height: "14px",
                          position: "relative",
                          objectFit: "scale-down",
                        }}
                        className="card-img-top mb-1"
                        alt="..."
                      />
                    )}{" "}
                    {post.title}
                  </p>
                  <p className="fw-bold">{formattedPrice}</p>
                </a>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopProd;
