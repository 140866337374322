import "./navbar.scss";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <nav
      className=" bg-body-tertiary fixed-bottom"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          <img
            src="../../img/logo.jpg"
            style={{
              width: "70px",
              height: "30px",

              objectFit: "cover",
            }}
            alt=""
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            {currentUser && (
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href={`/profile/${currentUser.storename}/`}
                >
                  Dashboard
                </a>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link active fw-bolder" href="/exclusive">
                Exclusive Products
              </a>
            </li>

            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </div>
              <ul className="dropdown-menu text-capitalize">
                <li>
                  <a className="dropdown-item" href="/cat/ClothingM">
                    men's Clothing
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/ClothingW">
                    Women's Clothing
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/accessoriesM">
                    Men's accessories
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/accessoriesW">
                    women's accessories
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/vehicles">
                    Vehicles
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/mobile">
                    Mobile devices
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/Perfumes">
                    Perfumes/incense
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/Book">
                    Books
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/furniture">
                    furniture/interior decor
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/Beauty">
                    Beauty/skin care
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/kitchen">
                    Kitchen
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/others">
                    Others
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              {currentUser && currentUser.id ? (
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  Logout
                </span>
              ) : (
                <a className="nav-link" href="/login">
                  Sign In As Vendor
                </a>
              )}
            </li>
          </ul>
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a className="navbar-brand m-1" href="/home">
          <img
            src="../../img/home.png"
            style={{
              width: "50px",
              height: "40px",

              objectFit: "scale-down",
            }}
            alt=""
          />
        </a>
        <a className="navbar-brand m-1" href="/exclusive">
          <img
            src="../../img/top.png"
            style={{
              width: "50px",
              height: "35px",
              objectFit: "scale-down",
            }}
            alt=""
          />
        </a>
        <a className="navbar-brand m-1" href="/cat">
          <img
            src="../../img/cat.png"
            style={{
              width: "50px",
              height: "35px",

              objectFit: "scale-down",
            }}
            alt=""
          />
        </a>
        {currentUser && (
          <a
            className="navbar-brand m-1"
            href={`/profile/${currentUser.storename}/`}
          >
            <img
              src="../../img/prof.png"
              style={{
                width: "50px",
                height: "35px",

                objectFit: "scale-down",
              }}
              alt=""
            />{" "}
          </a>
        )}
        {currentUser && currentUser.id ? (
          <span
            className="navbar-brand m-1"
            style={{ cursor: "pointer" }}
            onClick={logout}
          >
            <img
              src="../../img/out.png"
              style={{
                width: "50px",
                height: "35px",

                objectFit: "scale-down",
              }}
              alt=""
            />{" "}
          </span>
        ) : (
          <a className="navbar-brand m-1" href="/login">
            <img
              src="../../img/in.png"
              style={{
                width: "50px",
                height: "35px",

                objectFit: "scale-down",
              }}
              alt=""
            />{" "}
          </a>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
